import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import {
  HeartIcon,
  ChevronDownIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import {
  fetchSimilarPropertiesThunk,
  fetchSingleListingThunk,
  fetchMarketStatisticsThunk,
  fetchPropertyHistoryThunk,
  fetchImageThunk,
  fetchLocalDataThunk,
} from "../store/thunks/repliersThunk";
import Map from "../components/maplibre/Map";
import PropertyCarousel from "../components/properties/PropertyCarousel";
import ImageCarousel from "../components/properties/ImageCarousel";
import ImageMosaic from "../components/properties/ImageMosaic";
import PropertyHistory from "../components/properties/PropertyHistory";
import LocalPlaces from "../components/properties/LocalPlaces";
import MortgageCalculator from "../components/properties/MortgageCalculator";
import TaxTable from "../components/properties/TaxTable";
import MarketStatisticsChart from "../components/properties/MarketStatisticsChart";
import EstimatedValue from "../components/properties/EstimatedValue";
import CashFlowCalculator from "../components/properties/CashFlowCalculator"; // Import the new CashFlowCalculator component
import LoadingSpinner from "../components/LoadingSpinner";
import AddToFavoritesModal from "../components/favorites/AddToFavoritesModal";
import RemoveFromFavoritesModal from "../components/favorites/RemoveFromFavoritesModal";
import "./PropertyDetailsPage.css";

const PropertyDetailsPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const favoritesLists = useSelector((state) => state.favorites.lists);
  const { mlsNumber, boardId } = useParams();

  const [property, setProperty] = useState(null);
  const isSold = property?.soldPrice && property?.soldDate;

  const [address, setAddress] = useState({});

  const [similarProperties, setSimilarProperties] = useState({});
  const [isInFavorites, setIsInFavorites] = useState(false);
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [localData, setLocalData] = useState({});
  const [fetchedImages, setFetchedImages] = useState([]);
  const [propertyHistory, setPropertyHistory] = useState(null);
  const [marketStatistics, setMarketStatistics] = useState(null);
  const [loading, setLoading] = useState(true);

  const [activeSection, setActiveSection] = useState(null);
  const [openContact, setOpenContact] = useState(true);
  const [openHistory, setOpenHistory] = useState(true);
  const [openSold, setOpenSold] = useState(true);
  const [openSimilar, setOpenSimilar] = useState(true);
  const [openFinancials, setOpenFinancials] = useState(true);
  const [openStatistics, setOpenStatistics] = useState(true);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullExtras, setShowFullExtras] = useState(false);

  const sectionRefs = {
    details: useRef(null),
    statistics: useRef(null),
    history: useRef(null),
    sold: useRef(null),
    similar: useRef(null),
    financials: useRef(null),
    contact: useRef(null),
  };

  const [monthlyPayment, setMonthlyPayment] = useState(0);

  // Fetch all property-related data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        console.log("Fetching single listing...");
        const propertyResponse = await dispatch(
          fetchSingleListingThunk({ mlsNumber, boardId })
        ).unwrap();

        // Set address as soon as we have property data
        setAddress({
          unitNumber: propertyResponse.address?.unitNumber || "",
          streetNumber: propertyResponse.address?.streetNumber || "",
          streetName: propertyResponse.address?.streetName || "",
          streetSuffix: propertyResponse.address?.streetSuffix || "",
        });

        setProperty(propertyResponse);

        console.log("Fetching related data in parallel...");
        const [
          similarPropertiesResponse,
          propertyHistoryResponse,
          imageResponses,
          marketStatisticsResponse,
        ] = await Promise.all([
          dispatch(
            fetchSimilarPropertiesThunk({ mlsNumber, boardId })
          ).unwrap(),
          dispatch(
            fetchPropertyHistoryThunk({
              mlsNumber,
              boardId,
            })
          ).unwrap(),
          Promise.all(
            propertyResponse.images.map((imageName) =>
              dispatch(fetchImageThunk(imageName)).unwrap()
            )
          ),
          dispatch(
            fetchMarketStatisticsThunk({
              mlsNumber,
              boardId,
            })
          ).unwrap(),
        ]);

        setSimilarProperties(similarPropertiesResponse);
        setPropertyHistory(propertyHistoryResponse);
        setFetchedImages(imageResponses);
        setMarketStatistics(marketStatisticsResponse);
      } catch (error) {
        console.error("Failed to fetch property data:", error);
      } finally {
        setTimeout(() => setLoading(false), 1); // 1 ms buffer
      }
    };

    fetchData();
  }, [mlsNumber, boardId, dispatch]);

  // Check if the property is in favorites
  useEffect(() => {
    const checkIsInFavorites = () => {
      const isFavorite = favoritesLists.some((list) =>
        list.properties.some((prop) => prop.mlsNumber === property.mlsNumber)
      );
      setIsInFavorites(isFavorite);
    };

    if (property) {
      checkIsInFavorites();
    }
  }, [favoritesLists, property]);

  const handleHeartClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isInFavorites) {
      setShowRemoveModal(true);
    } else {
      setShowFavoritesModal(true);
    }
  };

  console.log("Property in PDP:", property);
  console.log("isSold", isSold);

  // New mobile friendly scroll
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // New mobile friendly scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      Object.entries(sectionRefs).forEach(([section, ref]) => {
        if (ref.current && scrollPosition >= ref.current.offsetTop) {
          setActiveSection(section);
        }
      });
    };

    const debouncedScroll = debounce(handleScroll, 100);

    window.addEventListener("scroll", debouncedScroll);
    return () => window.removeEventListener("scroll", debouncedScroll);
  }, []);

  // Handle scrolling behavior
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY;
  //     Object.entries(sectionRefs).forEach(([section, ref]) => {
  //       if (ref.current && scrollPosition >= ref.current.offsetTop) {
  //         setActiveSection(section);
  //       }
  //     });
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const scrollToSection = (section) => {
    sectionRefs[section].current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Render loading state or the property details
  if (loading) {
    const hasAddress =
      address.unitNumber ||
      address.streetNumber ||
      address.streetName ||
      address.streetSuffix;

    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <LoadingSpinner
            title={
              hasAddress
                ? `Loading ${
                    address.unitNumber ? `${address.unitNumber} -` : ""
                  } ${address.streetNumber || ""} ${address.streetName || ""} ${
                    address.streetSuffix || ""
                  }`
                : "Loading details"
            }
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header */}
      <div className="md:top-0 z-10 bg-white shadow-md">
        <div className="container mx-auto px-4 py-4 ">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-2 text-[#0A2342]">
              {property?.address.unitNumber
                ? `${property.address.unitNumber} - `
                : ""}
              {property?.address.streetNumber} {property?.address.streetName}{" "}
              {property?.address.streetSuffix}, {property?.address.city},{" "}
              {property?.address.state} {property?.address.zip}
            </h1>
            <button
              onClick={handleHeartClick}
              className="transition duration-200 mb-2"
              title={
                isInFavorites ? "Remove from Favorites" : "Add to Favorites"
              }>
              <HeartIcon
                className={`h-6 w-6 ${
                  isInFavorites
                    ? "text-[#0A2342] fill-[#0A2342]"
                    : "text-[#0A2342] fill-white"
                }`}
              />
              {/* <button>Favozurites</button> */}
            </button>
          </div>

          <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4 mb-4">
            <span
              className={`bg-[#0A2342] text-white text-lg px-3 py-1 ${
                isSold ? "font-semibold" : ""
              }`}>
              {isSold
                ? (() => {
                    const daysAgo = Math.floor(
                      (new Date() - new Date(property.soldDate)) /
                        (1000 * 60 * 60 * 24)
                    );
                    return daysAgo >= 0
                      ? `SOLD $${Math.round(
                          property.soldPrice
                        ).toLocaleString()} - ${daysAgo} days ago`
                      : `SOLD $${Math.round(
                          property.soldPrice
                        ).toLocaleString()} - ${new Date(
                          property.soldDate
                        ).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}`;
                  })()
                : property.listPrice
                ? `$${Math.round(property.listPrice).toLocaleString()}, ${
                    property.type
                  }`
                : property.type
                ? `${property.type}`
                : ""}
            </span>

            <span className="text-gray-600">
              {property.details.numBedrooms !== null &&
              property.details.numBedrooms !== undefined
                ? `${property.details.numBedrooms} Beds`
                : "0 Beds"}
              {" • "}
              {property.details.numBathrooms !== null &&
              property.details.numBathrooms !== undefined
                ? `${property.details.numBathrooms} Baths`
                : "0 Baths"}
              {property.details.sqft && property.details.sqft !== 0
                ? ` • ${property.details.sqft} sqft`
                : ""}{" "}
              {property.mlsNumber && property.mlsNumber !== 0
                ? ` • MLS #${property.mlsNumber}`
                : ""}
              {property.office && property.office.brokerageName
                ? ` • ${property.office.brokerageName}`
                : ""}
            </span>
          </div>
          <div className="flex space-x-4 overflow-x-auto py-1">
            {Object.keys(sectionRefs).map((section) => (
              <motion.button
                key={section}
                onClick={() => scrollToSection(section)}
                className={`navigation-button ${
                  activeSection === section ? "active" : "inactive"
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}>
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </motion.button>
            ))}
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="container mx-auto px-4 py-8">
        {/* Property Images */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div className="col-span-3 overflow-x-auto">
            {fetchedImages.length > 0 ? (
              window.innerWidth < 768 ? (
                <ImageCarousel images={fetchedImages} />
              ) : (
                <ImageMosaic images={fetchedImages} />
              )
            ) : (
              <img
                src="https://placehold.co/800x600"
                alt="Loading..."
                loading="lazy"
                className="w-full h-auto"
              />
            )}
          </div>
        </div>

        {/* Below the fold*/}
        <div className="space-y-4">
          <>
            {/* All the details */}
            <motion.div
              ref={sectionRefs.details}
              className="bg-white p-4"
              // className={`lazy-section ${
              //   activeSection === "details" ? "visible" : ""
              // }`}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2 className="text-xl font-semibold mb-4 text-[#0A2342]">
                Property Details
              </h2>
              {property.details.description && (
                <p className="mb-4">
                  {property.details.description.length > 200 ? (
                    <>
                      {showFullDescription
                        ? `${property.details.description} `
                        : `${property.details.description.substring(
                            0,
                            250
                          )}... `}
                      <button
                        onClick={() =>
                          setShowFullDescription(!showFullDescription)
                        }
                        className="text-white bg-[#0A2342] px-2 py-1hover:bg-[#0A2342] hover:opacity-70 transition-opacity duration-100">
                        {showFullDescription ? " Show less" : " Show more"}
                      </button>
                    </>
                  ) : (
                    property.details.description
                  )}
                </p>
              )}

              {property.details.extras && (
                <p className="mb-4">
                  {property.details.description.extras > 200 ? (
                    <>
                      {showFullExtras
                        ? `${property.details.extras} `
                        : `${property.details.extras.substring(0, 250)}... `}
                      <button
                        onClick={() => setShowFullExtras(!showFullExtras)}
                        className="text-white bg-[#0A2342] px-2 py-1hover:bg-[#0A2342] hover:opacity-70 transition-opacity duration-100">
                        {showFullExtras ? " Show less" : " Show more"}
                      </button>
                    </>
                  ) : (
                    <p>
                      <strong className="text-[#0A2342]">Extras:</strong>{" "}
                      {property.details.extras}
                    </p>
                  )}
                </p>
              )}

              {/* All the key-value details */}
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {property.details.propertyType && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Property Type
                    </h3>
                    <p>
                      {property.details.propertyType === "Att/Row/Townhouse"
                        ? "Attached / Row / Townhouse"
                        : property.details.propertyType}
                    </p>
                  </div>
                )}{" "}
                {property.address.neighborhood != null && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Neighborhood
                    </h3>
                    <p>{property.address.neighborhood}</p>
                  </div>
                )}
                {property.details.numBedrooms !== null && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Bedrooms</h3>
                    <p>{property.details.numBedrooms}</p>
                  </div>
                )}
                {property.details.numBathrooms !== null && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Bathrooms</h3>
                    <p>{property.details.numBathrooms}</p>
                  </div>
                )}
                {property.details.sqft && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Square Footage
                    </h3>
                    <p>{property.details.sqft}</p>
                  </div>
                )}
                <div>
                  <h3 className="font-semibold text-[#0A2342]">
                    Balcony / Patio
                  </h3>
                  <p>
                    {property.details.balcony &&
                    property.details.balcony !== "None" &&
                    property.details.balcony !== "N/A"
                      ? property.details.balcony
                      : "None"}{" "}
                    /{" "}
                    {property.details.patio &&
                    property.details.patio !== "None" &&
                    property.details.patio !== "N/A"
                      ? property.details.patio
                      : "None"}
                  </p>
                </div>
                {property.details.airConditioning && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Air Conditioning
                    </h3>
                    <p>{property.details.airConditioning}</p>
                  </div>
                )}
                {property.details.heating && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Heating</h3>
                    <p>{property.details.heating}</p>
                  </div>
                )}
                {property.details.virtualTourUrl && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Virtual Tour
                    </h3>
                    <a
                      href={property.details.virtualTourUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-blue-500 hover:text-blue-700">
                      View
                      <ArrowTopRightOnSquareIcon className="w-5 h-5 ml-2" />
                    </a>
                  </div>
                )}
                {property.lot.depth && property.lot.width && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Lot Size</h3>
                    <p>
                      {property.lot.depth} x {property.lot.width}{" "}
                      {property.lot.measurement}
                    </p>
                  </div>
                )}
                {property.details.waterSource && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Water Source
                    </h3>
                    <p>{property.details.waterSource}</p>
                  </div>
                )}
                {property.details.driveway && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Driveway</h3>
                    <p>{property.details.driveway}</p>
                  </div>
                )}
                {property.details.businessType && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Business Type
                    </h3>
                    <p>{property.details.businessType}</p>
                  </div>
                )}
              </div>
            </motion.div>

            {/* Condo Details */}
            {property.details.propertyType.toLowerCase().includes("condo") && (
              <motion.div
                ref={sectionRefs.condoDetails}
                className="bg-white p-4 mt-8"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <h2 className="text-xl font-semibold mb-4 text-[#0A2342]">
                  Condominium Details
                </h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                  {/* Amenities */}
                  {property.condominium.ammenities &&
                    property.condominium.ammenities.length > 0 && (
                      <div className="col-span-2">
                        <h3 className="font-semibold text-[#0A2342]">
                          Amenities
                        </h3>
                        <p>{property.condominium.ammenities.join(", ")}</p>
                      </div>
                    )}

                  {/* Exposure */}
                  {property.condominium.exposure && (
                    <div>
                      <h3 className="font-semibold text-[#0A2342]">Exposure</h3>
                      <p>{property.condominium.exposure}</p>
                    </div>
                  )}

                  {/* Stories */}
                  {property.condominium.stories && (
                    <div>
                      <h3 className="font-semibold text-[#0A2342]">Stories</h3>
                      <p>{property.condominium.stories}</p>
                    </div>
                  )}

                  {/* Locker */}
                  {property.condominium.locker &&
                    property.condominium.locker !== "None" && (
                      <div>
                        <h3 className="font-semibold text-[#0A2342]">Locker</h3>
                        <p>{property.condominium.locker}</p>
                      </div>
                    )}

                  {/* Parking */}
                  {property.condominium.parkingType && (
                    <div>
                      <h3 className="font-semibold text-[#0A2342]">Parking</h3>
                      <p>{property.condominium.parkingType}</p>
                    </div>
                  )}

                  {/* Pets */}
                  {property.condominium.pets && (
                    <div>
                      <h3 className="font-semibold text-[#0A2342]">Pets</h3>
                      <p>{property.condominium.pets}</p>
                    </div>
                  )}

                  {/* Property Manager */}
                  {property.condominium.propertyMgr && (
                    <div>
                      <h3 className="font-semibold text-[#0A2342]">
                        Property Manager
                      </h3>
                      <p>{property.condominium.propertyMgr}</p>
                    </div>
                  )}
                </div>
              </motion.div>
            )}

            {/* Local Places and Map */}
            <div className="mb-8 flex flex-col md:flex-row gap-4">
              {/* Local Places */}
              <div className="w-full md:w-1/2 flex-1">
                <LocalPlaces localData={localData} />
              </div>

              {/* Map */}
              <div className="w-full md:w-1/2">
                {property.map && (
                  <Map
                    locations={[
                      {
                        address: `${property.address.streetNumber} ${property.address.streetName} ${property.address.streetSuffix}, ${property.address.city}, ${property.address.state} ${property.address.zip}`,
                        longitude: property.map.longitude,
                        latitude: property.map.latitude,
                      },
                    ]}
                  />
                )}
              </div>
            </div>
            {/* Market Statistics */}
            <motion.div
              ref={sectionRefs.statistics}
              className="bg-white p-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
                onClick={() => setOpenStatistics(!openStatistics)}>
                Market Statistics
                <ChevronDownIcon
                  className={`h-6 w-6 ml-2 transform transition-transform ${
                    openStatistics ? "rotate-180" : "rotate-0"
                  }`}
                />
              </h2>
              {openStatistics && (
                <div className="flex flex-col gap-4">
                  <MarketStatisticsChart
                    property={property}
                    marketStatistics={marketStatistics}
                  />
                  <EstimatedValue
                    property={property}
                    marketStatistics={marketStatistics}
                  />
                </div>
              )}
            </motion.div>
            {/* History */}
            <motion.div
              ref={sectionRefs.history}
              className="bg-white p-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
                onClick={() => setOpenHistory(!openHistory)}>
                Property History
                <ChevronDownIcon
                  className={`h-6 w-6 ml-2 transform transition-transform ${
                    openHistory ? "rotate-180" : "rotate-0"
                  }`}
                />
              </h2>
              {openHistory && (
                <div className="w-full">
                  {propertyHistory?.history?.length > 0 ? (
                    <PropertyHistory history={propertyHistory.history} />
                  ) : (
                    <p>No property history available.</p>
                  )}
                </div>
              )}
            </motion.div>
            {/* Sold */}
            <motion.div
              ref={sectionRefs.sold}
              className={`bg-white ${openSold ? "" : "p-4"}`}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className={`text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center ${
                  openSold ? "p-4" : ""
                }`}
                onClick={() => setOpenSold(!openSold)}>
                Recently Sold
                <ChevronDownIcon
                  className={`h-6 w-6 ml-2 transform transition-transform ${
                    openSold ? "rotate-180" : "rotate-0"
                  }`}
                />
              </h2>
              {openSold && (
                <div className="pb-2">
                  {property.comparables && property.comparables.length > 0 ? (
                    <PropertyCarousel
                      properties={property.comparables.slice(0, 5)}
                      showing={5}
                      boardId={property.boardId}
                      hidePagination={true}
                    />
                  ) : (
                    <p className="text-center text-md font-semibold text-[#0A2342] mb-4">
                      Could not find any recently sold similar properties.
                    </p>
                  )}
                </div>
              )}
            </motion.div>
            {/* Similar Properties */}
            <motion.div
              ref={sectionRefs.similar}
              className={`bg-white ${openSimilar ? "" : "p-4"}`}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className={`text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center ${
                  openSimilar ? "p-4" : ""
                }`}
                onClick={() => setOpenSimilar(!openSimilar)}>
                Similar Listings
                <ChevronDownIcon
                  className={`h-6 w-6 ml-2 transform transition-transform ${
                    openSimilar ? "rotate-180" : "rotate-0"
                  }`}
                />
              </h2>
              {openSimilar && (
                <div className="pb-2">
                  {similarProperties.similar &&
                  similarProperties.similar.length > 0 ? (
                    <PropertyCarousel
                      // properties={similarProperties.similar.slice(0, 5)}
                      properties={similarProperties.similar.slice(0, 10)}
                      showing={10}
                      hidePagination={true}
                    />
                  ) : (
                    <p className="text-center text-md font-semibold text-[#0A2342] mb-4">
                      No similar listings available
                    </p>
                  )}
                </div>
              )}
            </motion.div>
            {/* Financial Analysis */}
            <motion.div
              ref={sectionRefs.financials}
              className="bg-white p-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
                onClick={() => setOpenFinancials(!openFinancials)}>
                Financial Analysis
                <ChevronDownIcon
                  className={`h-6 w-6 ml-2 transform transition-transform ${
                    openFinancials ? "rotate-180" : "rotate-0"
                  }`}
                />
              </h2>
              {openFinancials && (
                <div className="space-y-4">
                  {Object.entries(property.financials || {}).map(
                    ([key, value]) => (
                      <div
                        className="flex justify-between items-center"
                        key={key}>
                        <span>
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                        </span>
                        <span className="font-semibold text-[#0A2342]">
                          ${value ?? "N/A"}/mo
                        </span>
                      </div>
                    )
                  )}
                  <TaxTable taxes={property.taxes} />
                  <MortgageCalculator
                    property={property}
                    setMonthlyPayment={setMonthlyPayment}
                  />
                  <CashFlowCalculator
                    property={property}
                    monthlyPayment={monthlyPayment}
                  />
                </div>
              )}
            </motion.div>
            {/* Contact Form */}
            <motion.div
              ref={sectionRefs.contact}
              className="bg-white p-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
                onClick={() => setOpenContact(!openContact)}>
                Contact Form
                <ChevronDownIcon
                  className={`h-6 w-6 ml-2 transform transition-transform ${
                    openContact ? "rotate-180" : "rotate-0"
                  }`}
                />
              </h2>
              {openContact && (
                <form>
                  <div className="space-y-4 md:space-y-0 md:flex md:gap-4">
                    <div className="md:w-1/2 space-y-4">
                      <input
                        type="text"
                        placeholder="Name"
                        className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                        defaultValue={`${user.firstName} ${user.lastName}`}
                      />
                      <input
                        type="email"
                        placeholder="Email"
                        className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                        defaultValue={user.username}
                      />
                      <input
                        type="tel"
                        placeholder="Phone"
                        className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                      />
                    </div>
                    <div className="md:w-1/2">
                      <textarea
                        placeholder="Message"
                        rows="4"
                        className="w-full h-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"></textarea>
                    </div>
                  </div>
                  <motion.button
                    className="submit-button"
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.95 }}>
                    Send Message
                  </motion.button>
                </form>
              )}
            </motion.div>
          </>
        </div>
      </div>

      {showFavoritesModal && (
        <AddToFavoritesModal
          property={property}
          onClose={() => setShowFavoritesModal(false)}
        />
      )}
      {showRemoveModal && (
        <RemoveFromFavoritesModal
          property={property}
          onClose={() => setShowRemoveModal(false)}
        />
      )}
    </div>
  );
};

export default PropertyDetailsPage;
